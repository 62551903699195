import React, { Component } from 'react';
import { HashRouter, Route, Switch, Link, Redirect } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import Tour from 'reactour'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { isAuthenticated } from './auth';

const loading = () => <div className="animated fadeIn pt-3 text-center">Carregando...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const ClubsAdmin = React.lazy(() => import('./views/Pages/ClubsAdmin/List'));
const Login = React.lazy(() => import('./views/Pages/Login/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500/Page500'));
const ResetPassword = React.lazy(() => import('./views/Pages/ResetPassword/Reset'));
const ChangePassword = React.lazy(() => import('./views/Pages/ResetPassword/ChangePassword'));

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }
  render() {
    const { isOpen } = this.state;
    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/clubs" name="Clubs choose Page" render={props => <ClubsAdmin {...props}/>} />
              <Route exact path="/forgot-password" name="Forgot Page" render={props => <ResetPassword {...props}/>} />
              <Route exact path="/change-password" name="Change Password Page" render={props => <ChangePassword {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={props => isAuthenticated() ? <DefaultLayout {...props}/> : <Redirect to="/login"/>} />
            </Switch>
            <>
      { /* other stuff */}
      {/* <button onClick={()=>{this.setState({ isOpen: !isOpen })}} >t</button> */}
      <Tour
        steps={[{
          selector: '.sidebar-nav',
          content: 'This is my first Step',
        }]}
        isOpen={isOpen}
        onRequestClose={() => {}} />
    </>
    <ToastContainer />
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
